/* eslint-disable no-restricted-syntax */
import 'regenerator-runtime/runtime';
import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as styled from 'styled-components';
// @ts-ignore
import * as styled5 from 'styled-components-5';
import * as mobx from 'mobx';
import * as mobxReact from 'mobx-react';
import * as HttpTransport from '@estee/elc-http-transport';
import * as HypoContainer from 'hypo-container';
import * as ELCLogger from '@estee/elc-logging';
import { setupServiceInfo, addRequestIdleCallbackPolyfill } from '@estee/elc-universal-utils';

setupServiceInfo(__serviceInfo__);
addRequestIdleCallbackPolyfill();

// Check if it running in the Node
const isNode =
    typeof process !== 'undefined' && process.versions != null && process.versions.node != null;

if (!isNode) {
    if (!Element.prototype.matches) {
        Element.prototype.matches =
            Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
    }
}
function ajv() {}
ajv.prototype.validate = function () {
    return true;
};

const ElcRuntime = {
    ELCLogger,
    React,
    ReactDom,
    styled,
    styled5,
    mobx,
    mobxReact,
    ajv,
    HttpTransport,
    HypoContainer
};

export { ElcRuntime };
